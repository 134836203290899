@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter', sans-serif;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

.overlay-body::-webkit-scrollbar {
  display: none;
}

.announcement::-webkit-scrollbar {
  display: none;
}

.main-body {
  background: linear-gradient(5.39deg, rgba(23, 23, 26, 0.2) 77.14%, rgba(255, 255, 255, 0.2) 104.73%), #17171A;
  background-blend-mode: exclusion, normal;
}

table thead tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 0.75rem 1rem;
}

table tbody tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0.75rem 1rem;
}